import React from 'react';
import { errorCodecV2 } from '../../constants/error-codes';

const errorScreenContent = {
  [errorCodecV2.EXCEEDED_PLUS_LIMIT_RETRIABLE]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディのご利用可能額を超過しています。',
      [
        `ペイディアプリから`,
        <a
          key="すぐ払い"
          href={process.env.ONE_LINK_OVER_PLUS_LIMIT_CAN_RETRY}
          target="_blank"
          rel="noopener noreferrer"
        >
          すぐ払い
        </a>,
        `機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。`,
      ],
    ],
    noteText: [
      '※すぐ払いはコンビニ払いのみ利用可能です。毎月のご請求のお支払い方法を口座振替に設定している場合、ペイディのご利用明細にお引き落としが反映された翌日から14日頃までの間、コンビニ払いですぐ払いをご利用いただけます。',
    ],
    qrCodeContent: process.env.ONE_LINK_OVER_PLUS_LIMIT_CAN_RETRY,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
  },
  [errorCodecV2.EXCEEDED_PLUS_LIMIT]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    qrCodeContent: process.env.ONE_LINK_OVER_PLUS_LIMIT_CANNOT_RETRY,
    downloadImage: require('../../assets/over-limit-pay-now-not-eligible.png'),
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      [
        'ペイディの今月のご利用可能枠を超過しています。',
        <br key="break" />,
        'ペイディアプリから下記の機能を利用することで、ご利用いただけるようになる場合があります。',
      ],
    ],
    bulletedLists: [
      '今月のご利用分を3・6・12回あと払いに変更する',
      [
        <a
          key="すぐ払い機能"
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE}
        >
          すぐ払い機能
        </a>,
        'でご請求金額を支払う',
      ],
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT_RETRIABLE,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
    noteText: [
      '※すぐ払いはコンビニ払いのみ利用可能です。毎月のご請求のお支払い方法を口座振替に設定している場合、ペイディのご利用明細にお引き落としが反映された翌日から14日頃までの間、コンビニ払いですぐ払いをご利用いただけます。',
    ],
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_EXCEEDED_NLP_LIMIT,
    downloadImage: require('../../assets/over-limit-pay-now-not-eligible.png'),
  },

  [errorCodecV2.OVERDUE_BILL]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'お支払い待ちのご請求があります。',
      '下記のボタンからMyPaidyに進み、案内に従ってお支払い手続きを行ってください。',
    ],
    buttonText: '今すぐ支払う',
    buttonHref: process.env.ONE_LINK_OVERDUE_BILL,
    hasFooter: false,
  },

  [errorCodecV2.MERCHANT_REQUIRES_HOSHINO]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      [
        'ご利用の加盟店でペイディを利用するには、',
        <a
          key="本人確認"
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.ONE_LINK_HOSHINO}
        >
          本人確認
        </a>,
        'が必要です。お客様は本人確認が完了していないため、決済が承認されませんでした。',
      ],
      'ペイディアプリから本人確認後、もう一度お試しください。',
    ],

    qrCodeContent: process.env.ONE_LINK_HOSHINO,
    downloadImage: require('../../assets/hoshino-kyc.png'),
    hasFooter: false,
  },

  [errorCodecV2.MERCHANT_REQUIRES_KYC]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      [
        'ご利用の加盟店でペイディを利用するには、',
        <a
          key="本人確認"
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.ONE_LINK_MERCHANT_REQUIRES_KYC}
        >
          本人確認
        </a>,
        'が必要です。お客様は本人確認が完了していないため、決済が承認されませんでした。',
      ],
      'ペイディアプリから本人確認後、もう一度お試しください。',
    ],

    qrCodeContent: process.env.ONE_LINK_MERCHANT_REQUIRES_KYC,
    downloadImage: require('../../assets/hoshino-kyc.png'),
    hasFooter: false,
  },
  [errorCodecV2.GENERIC]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'お手数ですが、店舗が提供する他の決済方法を選択してください。',
      'ペイディでは、すべてのお客様に安心・安全にご利用いただける健全なサービスの運営を目指しており、その取り組みのひとつとして、ご注文ごとにご利用内容の確認を行っております。そのため、店舗や商品などのご注文内容によって、決済が承認されない場合があります。',
      'なお、詳細な審査基準に関しましてはお答えすることができません。ご迷惑をおかけして誠に申し訳ございませんが、何卒ご理解いただけますと幸いです。',
    ],
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_RETRIABLE_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディのご利用可能額を超過しています。',
      [
        `ペイディアプリから`,
        <a
          key="すぐ払い"
          href={process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X}
          target="_blank"
          rel="noopener noreferrer"
        >
          すぐ払い
        </a>,
        `機能でご請求金額をお支払いいただくと、ご利用いただけるようになる場合があります。`,
      ],
    ],
    noteText: [
      '※すぐ払いはコンビニ払いのみご利用可能です。',
      `※毎月のご請求のお支払い方法を「口座振替」に設定している場合、をご利用いただけません。お支払方法を「コンビニ払い・銀行振込」に変更後、コンビニですぐ払いをご利用ください。`,
    ],
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CAN_RETRY_1X,
    downloadImage: require('../../assets/over-limit-pay-now-eligible.png'),
  },
  [errorCodecV2.EXCEEDED_NLP_LIMIT_X1]: {
    title: 'ペイディの決済が承認されませんでした',
    description: [
      'ペイディの今月のご利用可能枠を超過しています。',
      '今月のご利用可能枠は、ペイディアプリでご確認いただけます。',
    ],
    downloadImage: require('../../assets/over-limit-pay-now-not-eligible.png'),
    qrCodeContent: process.env.ONE_LINK_CHECKOUT_OVER_NLP_LIMIT_CANNOT_RETRY_1X,
  },
};

export default errorScreenContent;
