// @flow
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import moment from 'moment';
import idx from 'idx';

import Frame from '../Frame/FrameContainer';
import { errorHandler, hanToZen, toHalfWidth } from '../../utils';
import { MixpanelHelpers, MIXPANEL_ACTION_PAGE_VIEW } from '../../utils/mixpanel';

import styles from './ThreePayConfirm.scss';
import type { ThreePayOption, ThreePayKind } from './type';

import { COMBINI_FEE, paymentStatusMap, tierMap } from '../../constants';

import ConfirmItem from './ConfirmItem';
import ConfirmItemOverviewCollapsible from './ConfirmItemOverviewCollapsible';
import PaymentDates from './PaymentDates';

type Props = {
  options: ThreePayOption[],
  unavailableOptions: ThreePayOption[],
  selectedOption: ThreePayOption,
  onPatch: Function,
};

const resultType = (kind: ThreePayKind, tier) => {
  if (tier === tierMap.DIGITAL) {
    return kind === '3pay' ? 'digital-3pay' : 'digital';
  }

  return kind === 'single_pay' ? 'single-pay' : kind;
};

const ThreePayConfirm = ({ selectedOption, onPatch, options, unavailableOptions }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const tier = useSelector(state => state.payment.tier);
  const isHighTicketMerchant = useSelector(state => state.merchant.isHighTicket);
  const npayOptions = options.filter(
    opt => opt.kind === '3pay' || opt.kind === '6pay' || opt.kind === '12pay'
  );
  const { pathname } = location;

  const npayEligible =
    selectedOption.kind === '3pay' ||
    selectedOption.kind === '6pay' ||
    selectedOption.kind === '12pay';

  // Pick a smaller amount for display
  // See https://paidy-portal.atlassian.net/browse/BDI-7
  const installmentPrice = selectedOption.installments.reduce(
    (min, opt) => Math.min(min, opt.amount),
    selectedOption.installments[0].amount
  );

  useEffect(() => {
    MixpanelHelpers.trackAction({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
      extraData: {
        'Available Options': options.map(option => ({
          kind: option.kind,
          reason: option.reason,
        })),
        'Unavailable Options': unavailableOptions.map(option => ({
          kind: option.kind,
          reason: option.reason,
        })),
      },
    });

    MixpanelHelpers.trackDuration({
      pathname,
      actionName: MIXPANEL_ACTION_PAGE_VIEW,
    });

    return () => {
      MixpanelHelpers.trackDuration({
        pathname,
        actionName: MIXPANEL_ACTION_PAGE_VIEW,
        shouldEndTracker: true,
      });
    };
  }, []);

  const onConfirm = () => {
    const payload = npayEligible
      ? {
          installment_plan_kind: selectedOption.kind,
        }
      : {
          number_of_installments: 1,
        };

    onPatch(payload)
      .then(res => {
        const status = idx(res, _ => _.data.status);

        if (!status || status === paymentStatusMap.REJECTED) {
          history.push('/result/rejected');

          return;
        }

        // If the consumer is prompted to upgrade due to purchasing from a high-ticket merchant
        if (isHighTicketMerchant && status === paymentStatusMap.PENDING) {
          history.push('/result/pending');

          return;
        }

        history.push(`/result/${resultType(selectedOption.kind, tier)}`);
      })
      .catch(err => {
        errorHandler({ err, history });
      });
  };

  const getNpayOptionsString = () => {
    return npayOptions
      .map((option, index, arr) =>
        hanToZen(arr[arr.length - 1 - index].installments.length.toString())
      )
      .join('・');
  };

  return (
    <Frame helpType={npayEligible ? 'threepay' : 'all'}>
      <div className={styles['scrollable-content']}>
        <b className={styles.title}>お支払い内容の確認</b>
        <ConfirmItem
          title={npayEligible ? '初回お支払い日' : 'お支払い日'}
          description={moment(selectedOption.installments[0].due_date).format('YYYY年MM月DD日')}
          explanation="口座振替の場合は支払日が異なります"
        />
        <ConfirmItem
          title="お支払い金額・回数"
          description={`${installmentPrice.toLocaleString('jp-ja')}円 × ${hanToZen(
            selectedOption.installments.length.toString()
          )}回`}
        />
        {npayEligible && (
          <ConfirmItem title="分割手数料" description="無料" explanation="銀行振込・口座振替のみ" />
        )}
        {npayEligible && (
          <ConfirmItem title="今後のお支払い日とお支払い金額">
            <ConfirmItemOverviewCollapsible
              shouldBeCollapsible={selectedOption.installments.length === 12}
            >
              <PaymentDates selectedOption={selectedOption} />
            </ConfirmItemOverviewCollapsible>
          </ConfirmItem>
        )}
        {npayEligible && (
          <div className={styles['notification-text']} data-testid="npay-notification">
            <p>
              ※ {toHalfWidth(getNpayOptionsString())}
              回あと払いの利用を一度確定すると、再度支払い回数を変更することはできませんのでご注意ください。
            </p>
            <p>※ 端数がある場合は各回のお支払い金額が異なります。</p>
            <p>※ コンビニ払いの場合、支払手数料が別途発生します （最大税込{COMBINI_FEE}円/回）。</p>
          </div>
        )}
      </div>
      <div className={styles.controls}>
        <button
          id="btn-confirm"
          data-testid="btn-confirm"
          onClick={onConfirm}
          className={styles.button}
          autoFocus
        >
          {isHighTicketMerchant ? '次へ' : 'お支払いを確定'}
        </button>
      </div>
    </Frame>
  );
};

export default ThreePayConfirm;
